import { useEffect } from 'react';
import { useCountryStore } from './model/store';
import { getCountries } from './api';

export const useCountries = () => {
  const { countries, setCountries } = useCountryStore(state => state);

  useEffect(() => {
    (async () => {
      if (!countries) {
        try {
          const res = await getCountries();
          setCountries(res.data);
        } catch (e) {
          console.error('FETCH_COUNTRIES', e);
        }
      }
    })();
  }, []);


  return countries;
};
