import React from 'react';
import { Button, Space, Table } from 'antd';
import { displayDate } from '../../../../shared/lib';
import { GetRequestsApiParams, IRequest, REQUEST_STATUS_COLOR, REQUEST_STATUS_TITLE, RequestStatuses } from '../..';
import { Container, Typography } from '../../../../shared/ui';
import { Link } from 'react-router-dom';
import { Filters, FormData } from '../RequestTableFilters';


interface PropTypes<T> {
  data: IRequest[] | undefined,
  loading: boolean,
  title?: string,
  disableFilters?: boolean,
  setFilters?: (data: GetRequestsApiParams) => void,
  clearFilters?: () => void
}

export function RequestsTable<T>({ data, loading, disableFilters = false, title = 'Requests', setFilters, clearFilters }: PropTypes<T>) {

  const updateFilters = (formData: FormData) => {
    const { date, ...otherData } = formData;
    const fromDate = date && date[0].toDate() || null;
    const toDate = date && date[1].toDate() || null;
    if (fromDate) fromDate.setHours(0, 0, 0);
    if (toDate) {
      toDate.setDate(toDate.getDate() + 1);
      toDate.setHours(0, 0, 0);
    }

    setFilters && setFilters({
      type: otherData.type,
      status: otherData.status,
      fromDate: fromDate,
      toDate: toDate
    });
  };

  return (
    <div>
      <Container marginBottom={24}>
        <Space size={'middle'}>
          <Typography.Title level={3} $noMargin>
            {title}
          </Typography.Title>

          {!disableFilters && clearFilters && <Filters
            onConfirm={updateFilters}
            onCancel={clearFilters}
          />}
        </Space>
      </Container>

      <Table
        dataSource={data}
        loading={loading}
      >
        <Table.Column
          title="Date"
          dataIndex="createdAt"
          key="date"
          render={(date: string) => displayDate(date, true)}
        />
        <Table.Column title="Object" dataIndex="object" key="object" />
        <Table.Column title="Type" dataIndex="type" key="type" />
        <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status: RequestStatuses) => (
            <Typography.Text type={REQUEST_STATUS_COLOR[status]}>
              {REQUEST_STATUS_TITLE[status]}
            </Typography.Text>
          )}
        />
        <Table.Column
          key="details"
          align={'center'}
          render={(request) => (
            <Link to={`/request/${request.id}`}>
              <Button type={'primary'}>
                Details
              </Button>
            </Link>
          )}
        />
      </Table>
    </div>
  );
};
