import React from 'react';
import {
  CreateRequestForm, GoodsCheckTypes,
  RequestTypeType
} from 'entities/request';
import { CountrySelect, Form, Input, Select } from 'shared/ui';
import { CommonFormFields } from '../commonFormFields';
import { displayConstValue } from '../../../../shared/lib';
import { DatePicker } from 'antd';
import styles from '../Form.module.css';

interface Props {
  form: any,
  createRequest: (formData: CreateRequestForm) => void
  requestType: RequestTypeType
}

export const GoodsForm = ({ form, createRequest, requestType }: Props) => {

  return (
    <Form
      form={form}
      onFinish={createRequest}
      colon={false}
      className={styles.form}
    >
      <Form.Item
        label="Product code"
        name="productCode"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country of origin"
        name="countryOfOriginId"
        rules={[{ required: true, message: 'Required' }]}
      >
        <CountrySelect />
      </Form.Item>
      <Form.Item
        label="Delivery country"
        name="deliveryCountryId"
        rules={[{ required: true, message: 'Required' }]}
      >
        <CountrySelect />
      </Form.Item>
      <Form.Item
        label="Country of the deal"
        name="countryOfTheDeal"
      >
        <CountrySelect />
      </Form.Item>
      <Form.Item
        label="Type of check"
        name="typeOfCheck"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select
          options={Object.values(GoodsCheckTypes).map(item => (
            {
              label: displayConstValue(item),
              value: item
            })
          )}
          showSearch
        />
      </Form.Item>
      <Form.Item
        label="Planned delivery date"
        name="plannedDeliveryDate"
      >
        <DatePicker />
      </Form.Item>


      <CommonFormFields requestType={requestType} />
    </Form>
  );
};
