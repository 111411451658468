import { create } from 'zustand';
import { docType } from './types';
import { RequestTypeType } from 'entities/request';


interface State {
  [RequestTypeType.PERSONAL]: docType[] | null,
  [RequestTypeType.COMPANY]: docType[] | null,
  setDocTypes: (list: docType[], type: RequestTypeType) => void
}

export const useDocTypeStore = create<State>((set) => ({
  [RequestTypeType.PERSONAL]: null,
  [RequestTypeType.COMPANY]: null,
  setDocTypes: (list: docType[], type: RequestTypeType) => set((state) => (
    { ...state, [type]: list }
  ))
}));
