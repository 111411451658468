import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { RequestDetails } from 'widgets/request';
import { AddRequestReportModal } from 'features/request';
import { adminRequestApi } from 'entities/request';
import { RouterMode, useRouterStore } from 'shared/store';
import { Container, Button, Breadcrumb, Skeleton } from 'shared/ui';


export const RequestDetailsAdminPage = () => {
  const { id } = useParams();
  const routerMode = useRouterStore(state => state.mode);
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');

  return (
    <div>
      <Breadcrumb
        items={[
          { title: <Link to={'/'}>Requests</Link> },
          { title: title ? title : <Skeleton.Button size={'small'} /> }
        ]}
      />

      <RequestDetails
        apiFunc={adminRequestApi.getRequestDetails}
        setTitle={setTitle}
      />


      {routerMode === RouterMode.admin && <Container marginTop={32}>
        <Button type={'primary'} onClick={() => setModalOpen(true)}>
          Add report
        </Button>
      </Container>}

      <AddRequestReportModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        requestId={`${id}`}
      />
    </div>
  );
};
