import axios from 'axios';
import { API_URL } from 'shared/config';
import { PricingPlan } from '../model/types';

export const getPricingPlans = () => (
  axios.get<PricingPlan[]>(`${API_URL}/api/v1/investor/pricingPlan`)
);

const buyRequests = (data: { investorTypesCheckId: string }) => (
  axios.post(`${API_URL}/api/v1/investor/buyRequests`, data)
);

export const pricingPlanApi = {
  buyRequests
};
