import React from 'react';
import { useCountries } from 'entities/country';
import { Select } from 'antd';


export const CountrySelect = (props: any) => {
  const countries = useCountries();

  return (
    <Select
      options={countries || []}
      fieldNames={{ label: 'country', value: 'id' }}
      showSearch
      optionFilterProp={'country'}
      placeholder={'Select a country'}
      {...props}
    />
  );
};
