import React  from 'react';
import { useThemeStore } from '../store';
import { ConfigProvider } from 'antd';

type PropTypes = {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: PropTypes) => {
  const currentTheme = useThemeStore(state => state.currentTheme);

  return (
    <ConfigProvider theme={{ algorithm: currentTheme }}>
      {children}
    </ConfigProvider>
  );
};
