import { create } from 'zustand';
import { theme } from 'antd';

const { defaultAlgorithm, darkAlgorithm } = theme;


export const themesTypes = {
  light: 'light',
  dark: 'dark'
};
export type themeType = typeof themesTypes[keyof typeof themesTypes];

export const themeToAlgorithm = {
  [themesTypes.light]: defaultAlgorithm,
  [themesTypes.dark]: darkAlgorithm
};

interface ThemeState {
  currentTheme: any,
  currentThemeType: themeType,
  setTheme: (nextTheme: themeType) => void
}

// @ts-ignore
const initTheme: themeType = (localStorage.getItem('theme') && themesTypes[localStorage.getItem('theme')])
  ? localStorage.getItem('theme')
  : themesTypes.light;
export const useThemeStore = create<ThemeState>((set) => ({
  currentTheme: themeToAlgorithm[initTheme],
  currentThemeType: initTheme,
  setTheme: (nextTheme: themeType) => set(() => {
    localStorage.setItem('theme', nextTheme);
    return {
      currentTheme: themeToAlgorithm[nextTheme],
      currentThemeType: nextTheme
    };
  })
}));
