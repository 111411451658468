import React from 'react';
import { IRequestType, useRequestTypes } from 'entities/request';
import { Card, Button } from 'shared/ui';
import { pricingPlanApi } from '../../../../entities/pricingPlan/api';
import styled from 'styled-components';
import { message } from 'antd';
import { useAppProcessStore } from '../../../../entities/appProcess';
import { getErrorText } from '../../../../shared/lib';

interface ServiceCardProps {
  data: IRequestType | null,
  createRequest: (id: string) => void
}

export const ServiceCard = ({ data, createRequest }: ServiceCardProps) => {
  const { refetch } = useRequestTypes();
  const [messageApi, messageContext] = message.useMessage();
  const { setIsLoading } = useAppProcessStore();

  const buyRequest = async () => {
    if (data?.id) {
      try {
        setIsLoading(true);
        const res = await pricingPlanApi.buyRequests({ investorTypesCheckId: data.id });
        if (res?.data?.paymentUrl) window.open(res?.data?.paymentUrl, '_blank');

        await refetch();
      } catch (e) {
        messageApi.error(getErrorText(e));
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <StyledCard
      title={data?.typesCheck?.name}
      loading={!data}
      actions={data && data.paid ?
        [
          <Button
            onClick={() => data && createRequest(data.id)}
            type={'primary'}
          >
            New request
          </Button>
        ] : [
          <Button onClick={buyRequest} disabled={!data}>
            Buy request
          </Button>
        ]
      }
    >
      <div>
        Available requests: {data?.paid ? 1 : 0}
      </div>
      {data?.typesCheck && <div>
        Price:&thinsp;
        {data.countTestReq > 0 && data.typesCheck.priceTest ? data.typesCheck.priceTest : data.typesCheck.price}
        &thinsp;
        {data.typesCheck.currency}
      </div>}

      {messageContext}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  .ant-card-head {
    height: 100%;
  }
  .ant-card-head-title {
    white-space: unset;
    padding: 12px 0;
  }
`;
