import React from 'react';
import { InvestorRequestsTable, AdminRequestsTable } from 'widgets/request';
import { ServicesList } from 'widgets/servicesList';
import { Container } from 'shared/ui';


export const MainPage = () => {

  return (
    <Container marginTop={24}>
      <ServicesList />

      <Container marginTop={48}>
        <InvestorRequestsTable />
      </Container>
    </Container>
  );
};

export const AdminMainPage = () => {

  return (
    <Container marginTop={24}>
      <AdminRequestsTable />
    </Container>
  )
};
