// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_form__YwG5p .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {\n  position: absolute;\n  right: -6px;\n}\n.Form_form__YwG5p .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/widgets/newRequestModal/ui/Form.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".form :global .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {\n  position: absolute;\n  right: -6px;\n}\n.form :global .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Form_form__YwG5p"
};
export default ___CSS_LOADER_EXPORT___;
