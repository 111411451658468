import React, { useState } from 'react';
import { requestApi, useRequestTypes } from 'entities/request';
import { Row, Col } from 'shared/ui';
import { ServiceCard } from './ui/serviceCard';
import { NewRequestModal } from '../newRequestModal';
import { useQuery } from '@tanstack/react-query';

const FALSE_REQUEST_ID = '-1';
export const ServicesList = () => {
  const { data } = useQuery({
    queryKey: ['requestTypes'],
    queryFn: requestApi.getRequestTypes,
    select: data => data.data
  });
  const [newRequestId, setNewRequestId] = useState(FALSE_REQUEST_ID);

  return (
    <div>
      <Row gutter={[16, 16]} justify={'center'}>
        {(data || [null, null, null]).map((item, key) => (
          <Col span={8} key={key}>
            <ServiceCard
              data={item}
              createRequest={(id) => setNewRequestId(id)}
            />
          </Col>
        ))}
      </Row>

      <NewRequestModal
        isOpen={newRequestId !== FALSE_REQUEST_ID}
        onClose={() => setNewRequestId(FALSE_REQUEST_ID)}
        requestTypeId={newRequestId}
      />
    </div>
  );
};
