import React from 'react';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../api';
import { EnterEmailEntity, EnterEmailFormType } from 'entities/auth';
import { AuthPage, AuthType } from 'shared/types';
import { useInvestorStore } from 'shared/store';
import { message } from 'antd';
import { useAppProcessStore } from '../../../../entities/appProcess';
import { getErrorText } from '../../../../shared/lib';

export const EnterEmailSignIn = () => {
  const navigate = useNavigate();
  const setInvestor = useInvestorStore(state => state.setInvestor);
  const [messageApi, messageContext] = message.useMessage();
  const { setIsLoading } = useAppProcessStore();

  const startLogin = async ({ email }: EnterEmailFormType) => {
    try {
      setIsLoading(true);

      const res = await authApi.startLogin(email);
      setInvestor({ ...res.data });

      navigate(`/auth/${AuthType.SIGN_IN}/${AuthPage.CONFIRM_EMAIL}?email=${email}`);
    } catch (e) {
      messageApi.error(getErrorText(e));
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {messageContext}
      <EnterEmailEntity onNext={startLogin} />
    </div>
  );
};
