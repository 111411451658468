import React from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthPage, AuthType } from 'shared/types';
import { authApi } from '../../api';
import { useInvestorStore } from 'shared/store';
import { EnterEmailEntity, EnterEmailFormType } from 'entities/auth';
import { requestApi } from 'entities/request';
import { useAppProcessStore } from 'entities/appProcess';
import { message } from '../../../../shared/ui';
import { getErrorText } from '../../../../shared/lib';


type EnterEmailParams = {
  type: AuthType
}

export const EnterEmailSignUp = () => {
  let { type } = useParams<EnterEmailParams>();
  const setInvestor = useInvestorStore(state => state.setInvestor);
  const [messageApi, messageContext] = message.useMessage();
  const { setIsLoading } = useAppProcessStore();
  const navigate = useNavigate();

  const onNext = async ({ email }: EnterEmailFormType) => {
    try {
      setIsLoading(true);
      const res = await authApi.createInvestor(email);

      const { accessToken, role, ...investorData } = res.data;
      localStorage.setItem('token', accessToken);
      localStorage.setItem('userRole', role);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      setInvestor({ ...investorData, role });

      authApi.sendEmailCode().then();
      requestApi.generateRequestTypes().then();

      navigate(`/auth/${type}/${AuthPage.CONFIRM_EMAIL}`);
    } catch (e) {
      messageApi.error(getErrorText(e));
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };


  return <div>
    {messageContext}
    <EnterEmailEntity onNext={onNext} />
  </div>;
};
