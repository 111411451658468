import { create } from 'zustand';
import { Country } from '../../../shared/types';

interface CountryState {
  countries: Country[] | null,
  setCountries: (types: Country[]) => void
}

export const useCountryStore = create<CountryState>((set) => ({
  countries: null,
  setCountries: (countries: Country[]) => set((state) => (
    { ...state, countries: countries }
  ))
}));
