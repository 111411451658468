import axios from 'axios';
import { API_URL } from 'shared/config';
import { ICreateInvestor } from 'shared/types';

const getInvestor = () => {
  return axios.get(`${API_URL}/api/v1/investor`);
};

const createInvestor = (email: string) => {
  return axios.post<ICreateInvestor>(`${API_URL}/api/v1/investor`, { email });
};

const sendEmailCode = () => {
  return axios.get(`${API_URL}/api/v1/investor/sendEmailSms`);
};

const verifyEmail = (code: string) => {
  return axios.post(`${API_URL}/api/v1/investor/verifyEmail`, { code });
};

const setInvestorPhone = (phone: string) => {
  return axios.post(`${API_URL}/api/v1/investor/phone`, { phone });
};

const sendPhoneCode = () => {
  return axios.get(`${API_URL}/api/v1/investor/sendPhoneSms`);
};

const verifyPhone = (code: string) => {
  return axios.post(`${API_URL}/api/v1/investor/verifyPhone`, { code });
};

const startLogin = (email: string) => {
  return axios.post(`${API_URL}/api/v1/auth`, { email });
};

const getLoginToken = (data: {email: string, code: string}) => {
  return axios.post(`${API_URL}/api/v1/auth/token`, { email: data.email, code: data.code });
};

export const authApi = {
  getInvestor,
  createInvestor,
  sendEmailCode,
  verifyEmail,
  setInvestorPhone,
  sendPhoneCode,
  verifyPhone,
  startLogin,
  getLoginToken
};
