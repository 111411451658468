import { useDocTypeStore } from './model/store';
import { useEffect } from 'react';
import { getDocTypes } from './api';
import { RequestTypeType } from 'entities/request';


export const useDocTypes = () => {
  const { setDocTypes, ...types } = useDocTypeStore(state => state);

  const fetchDocTypes = async (type: RequestTypeType) => {
    try {
      const res = await getDocTypes(type);
      setDocTypes(res.data, type);
    } catch (e) {
    }
  };

  useEffect(() => {
    (async () => {
      if (!types[RequestTypeType.COMPANY]) {
        fetchDocTypes(RequestTypeType.COMPANY);
      }
      if (!types[RequestTypeType.PERSONAL]) {
        fetchDocTypes(RequestTypeType.PERSONAL);
      }
    })();
  }, []);


  return {
    [RequestTypeType.PERSONAL]: types[RequestTypeType.PERSONAL],
    [RequestTypeType.COMPANY]: types[RequestTypeType.COMPANY],
    [RequestTypeType.MANUAL]: null,
    [RequestTypeType.MANUAL_KYB]: null
  };
};

export * from './model/types';
